import React, { Component } from "react";
import {
  Button,
  TextArea,
  Input,
  Dropdown,
  Table,
  Form,
} from "semantic-ui-react";
import axios from "axios";
import Loading from "../../../Loading/Loading";

class AddGame extends Component {
  state = {
    tourId: this.props.tourId,
    gameTypes: this.props.gameTypes,
    round: 1,
    selectedGame: null,
    description: "",
    maxRounds: this.props.rounds,
    listedRounds: this.props.listedRounds,
    disabled: this.props.listedRounds.includes(1),
  };

  static getDerivedStateFromProps(props, state) {
    if (props.rounds !== state.maxRounds) {
      return {
        maxRounds: props.rounds,
        listedRounds: props.listedRounds,
        disabled: props.listedRounds.includes(state.round),
      };
    }
    return null;
  }

  changeRound = (e, { value }) => {
    this.setState({
      round: value,
      disabled: this.state.listedRounds.includes(parseInt(value)),
    });
  };

  changeGame = (e, { value }) => {
    this.setState({ selectedGame: value });
  };

  changeDescription = (e, { value }) => {
    this.setState({ description: value });
  };

  handleSubmit = async () => {
    try {
      const res = await axios.post("/api/addteamgame", {
        tourId: this.state.tourId,
        round: this.state.round,
        game: this.state.selectedGame,
        description: this.state.description,
      });
      const newGame = {
        ...res.data[0],
        game_name: this.state.gameTypes.find(
          (type) => type.key === this.state.selectedGame
        ).text,
        status: "New",
      };
      this.props.addGame(newGame);
      this.handleCancel();
    } catch (error) {
      console.error("Failed to submit game:", error);
    }
  };

  handleCancel = () => {
    this.props.closeModal();
  };

  render() {
    const { gameTypes, isLoading, round, description, disabled } = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <Table celled structured>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Input
                  name="Round"
                  label="Round"
                  max={this.state.maxRounds}
                  min={1}
                  type="number"
                  value={round}
                  onChange={this.changeRound}
                />
              </Table.Cell>
              <Table.Cell>
                <Dropdown
                  label="Game"
                  selection
                  placeholder="Select game"
                  options={gameTypes}
                  value={this.state.selectedGame}
                  onChange={this.changeGame}
                  disabled={disabled}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2">
                <Form>
                  <TextArea
                    name="Description"
                    placeholder="Description"
                    style={{ minHeight: 100 }}
                    value={description}
                    onChange={this.changeDescription}
                  />
                </Form>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <br />
        <Button primary onClick={this.handleSubmit} disabled={disabled}>
          Submit
        </Button>
        <Button secondary onClick={this.handleCancel}>
          Cancel
        </Button>
      </div>
    );
  }
}

export default AddGame;
